.homeStripeOdd {
  .SolutionsImageRight {
    padding-top: $token-spacing-6;
  }

  h2[data-testid="solutionOverviewHeading"] {
    margin-bottom: $token-spacing-8;
  }

  section {
    img#solution-area-overview-img {
      margin-top: -17rem !important;
    }

    &#textBlockWithIcon-section {
      background-color: $stripe-background-color;
    }

    &#homeSolutionsOverview-section {
      background-color: $hsOverviewSection-bgcolor;
    }

    &#featureProducts-section {
      background-color: $stripe-background-color;
    }
  }

  p[data-testid="solutionOverviewSubHeading"] {
    ol {
      li:not(:last-child) {
        margin-bottom: $token-spacing-8;
      }

      li:before {
        content: "✓";
        margin-right: $token-spacing-4;
        color: #6FA276;
      }

      li {
        list-style: none;
        display: flex;
      }
    }
  }

  img[data-testid="iconImage"] {
    color: #0C2074;
  }
}
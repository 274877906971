@import '@/styles/theme/variables.scss';

.loginWidgetContainer {
  h1 {
    font-weight: $token-font-weight-bold !important;
    font-size: $token-font-size-3xl !important;
    line-height: $token-spacing-10 !important;
    color: $token-text-color-brand-primary !important;
    margin-bottom: $token-spacing-7 !important;
  }

  .widgetLoader {
    min-height: $token-spacing-410px !important;
  }
  .disableWidget {
    display: none;
  }

  .enableWidget {
    display: block;
  }
}

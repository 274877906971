@import '@/styles/theme/variables.scss';

.challengeModal {
    h1 {
        line-height: 1.25 !important;
    }
    p {
        font-weight: unset !important;
        font-size: unset;
        line-height: unset;
        letter-spacing: unset !important;
        text-transform: unset !important;
        padding-bottom: unset !important;
    }
}
.challengeWidgetDiv {
    min-height: 3rem;
}
.pageChallengeWidgetDiv {
    min-height: 4rem;
}
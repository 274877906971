@import '@/styles/theme/variables.scss';

@media (min-width: $token-breakpoint-x-large) {
  .gridWrapper {
    max-width: $token-spacing-1650px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: $token-breakpoint-large) {
  .main {
    margin: $token-spacing-0 $token-spacing-8 !important;
  }
}

@media only screen and (max-width: 767.99px) {
  .main {
    margin: $token-spacing-0 $token-spacing-4;
  }
}

.contentBlock {
    min-height: $token-spacing-410px;
    max-height: auto;
}

.notification {
  padding: 0 $token-spacing-6;
}

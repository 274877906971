@import '@/styles/theme/variables.scss';

@mixin login_password($margin_bottom) {
  margin-bottom: $margin_bottom;
}

.loginForm {
  margin-top: $token-spacing-2;

  h1 {
    font-weight: $token-font-weight-bold;
    font-size: $token-font-size-3xl;
    line-height: $token-spacing-10;
    color: $token-text-color-brand-primary;
    margin-bottom: $token-spacing-7;
  }

  p {
    font-weight: $token-font-weight-reg;
    font-size: $token-font-size-base;
    line-height: $token-line-height-loose;
    color: $token-text-color-secondary;
    margin-bottom: $token-spacing-4;
  }

  .loginPassword,
  .modal_loginPassword,
  .loginUser,
  .modal_loginUser {
    label {
      line-height: $token-line-height-tight !important;
    }
  }

  .loginUser,
  .modal_loginUser {
    @include login_password($token-spacing-10);
  }

  .loginPassword,
  .modal_loginPassword {
    @include login_password($token-spacing-12);
  }
  
  .elavonLoginUIStyles,
  .elavonModalUIStyles {
    @include login_password($token-spacing-16px);
  }

  .accountRecoveryUIStyles {
    margin-bottom: $token-spacing-5;

    a {
      text-decoration: none !important;

      &:first-child {
        margin-right: $token-spacing-8;
      }
    }
  }
  
  .elavonAccountRecoveryUIStyles {
    margin-bottom: $token-spacing-5;
    text-align: right;

    a {
      text-decoration: none !important;

      //&:first-child {
      //  margin-right: $token-spacing-0;
      //}
    }
  }

  .loginButton {
    margin: $token-spacing-2 $token-spacing-0 $token-spacing-4 $token-spacing-0 !important;
    width: 100%;
    font-weight: $token-font-weight-med !important;
    line-height: $token-line-height-loose !important;
    justify-content: center;
  }

  .buttonSignUpUIStyles {
    line-height: $token-line-height-flat !important;
    font-size: $link-subtle-font-size !important;
  }

  .elavonButtonSignUpUIStyles {
    line-height: $token-line-height-flat !important;
    font-size: $token-font-size-sm !important;
  }

  .notRegisteredUser {
    color: $token-text-color-secondary !important;
  }

  span {
    float: none !important;
    font-weight: normal;
    padding: $token-spacing-0 !important;
  }
}

.notification {
  margin-bottom: $token-spacing-6;

  p {
    margin-bottom: $token-spacing-0;
    display: inline-block !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: $token-breakpoint-large) {
  .loginForm {
    width: 75%;
  }
}

@media only screen and (max-width: 767.99px) {
  .usernameRecoveryUIStyles {
    margin-left: $token-spacing-0 !important;
  }
}
@import '@/styles/theme/variables.scss';

.regionPickerWrapper {
    float: left !important;
    padding: 22px $token-spacing-6 10px $token-spacing-0;
}
.regionPickerTwoTierWrapper {
    float: left !important;
    padding: $token-spacing-base 0 $token-spacing-base $token-spacing-0;
    span {
        font-size: $token-font-size-14px !important;
    }
}

.regionPickerMobileWrapper {
    margin: $regionPicker-regionPickerMobileWrapper-margin !important;
}

@media only screen and (min-width: $token-spacing-667px) and (max-width: $token-spacing-1280px) {
    .regionPickerMobileWrapper {
        margin: $regionPicker-regionPickerTabletWrapper-margin !important;
    }
}

@import '@/styles/theme/mixins.scss';
@import '@/styles/theme/variables.scss';

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: $inputSearch-searchContainer-margin-right;
  margin-top: $inputSearch-searchContainer-margin-top;
  .usbSearch {
    color: $token-text-color-primary;
    display: flex;
    flex-wrap: nowrap;
    font-family: $token-font-base;
    -webkit-font-smoothing: antialiased;
    font-size: $token-font-size-base;
    position: relative;
    width: 100%;

    &--row {
      align-items: start;
    }

    &--column {
      flex-direction: column;

      & .usb-search_button.usb-button {
        margin: $token-spacing-3 $token-spacing-0 $token-spacing-0;
        width: 100%;
      }
    }

    // this will remove the tap highlight from a "long press" on mobile browsers. It includes a universal child selector so use wisely!
    @include removeHighlight($token-text-color-inverse-primary);

    &_container {
      display: inline-block;
      position: relative;
      width: 100%;

      &.search-input-focus {
        &::after {
          content: "";
          border: $token-border-width-large solid $search-input-focus-border-color;
          border-radius: $token-rounded-sm;
          height: calc(100% + #{$token-spacing-2});
          position: absolute;
          left: -$token-spacing-1;
          top: -$token-spacing-1;
          width: calc(100% + #{$token-spacing-2});
          z-index: $token-spacing-0;
        }
      }
    }

    &_input {
      @include bodyMedium($search-input-value-text-color);
      font-size: $inputSearch-searchContainer-usbSearch-input-font-size;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: $token-rounded-sm;
      box-shadow: none;
      margin-bottom: $token-spacing-0;
      margin-left: $token-spacing-0;
      padding: $inputSearch-searchContainer-usbSearch-input-padding;
      transition: width 100ms ease;
      will-change: width;
      width: $inputSearch-searchContainer-usbSearch-input-width;

      border: $token-spacing-xs solid $token-color-grey-60;
      background: $token-utility-white;

      &:focus {
        outline: $token-spacing-small solid $search-input-focus-border-color;
        outline-offset: $token-spacing-small;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }

      &::-ms-clear,
      &::-ms-reveal {
        display: none;
        width: $token-spacing-0;
        height: $token-spacing-0;
      }
    }

    &_label {
      &_icon {
        position: absolute;
        transform: translateY(-50%);
        margin-left: $token-spacing-12px;
        margin-top: $inputSearch-searchContainer-usbSearch-label-icon-margin-top;

        svg {
          height: $inputSearch-desktopSearchContainer-usbSearch-label-icon-svg-height;
          width: $inputSearch-searchContainer-usbSearch-label-icon-svg-width;
        }
      }
    }

    &_clear.usb-button.button--text {
      height: $token-spacing-10;
      margin: $token-spacing-0;
      max-height: none;
      padding: $token-spacing-0;
      position: absolute;
      right: $token-spacing-1;
      top: $token-spacing-0;
      bottom: $token-spacing-0;
      width: $token-spacing-10;
      visibility: visible;

      .usb-icon {
        margin: $token-spacing-0;
        max-height: $token-spacing-5;
        color: $token-background-dark;

        svg path {
          color: $search-input-clear-icon-fill-color;
        }
      }

      &:hover {
        border-color: transparent;

        .usb-icon {
          svg {
            fill: $search-input-hover-clear-icon-fill-color;
          }
        }
      }

      &:focus {
        &::before {
          top: -$token-spacing-1;
          left: -$token-spacing-1;
          height: calc(100% + #{$token-spacing-2});
          width: calc(100% + #{$token-spacing-2});
        }
      }
    }

    &_button {
      &-container {
        position: relative;
      }

      &.usb-button {
        margin: $token-spacing-0;
        margin-left: $token-spacing-3;
      }
    }

    &_combobox {
      background-color: $token-background-primary;
      border: $token-border-width-default solid $token-border-base;
      border-radius: $token-rounded-sm;
      position: absolute;
      top: calc(100% + #{$token-spacing-small});
      left: 0;
      width: 100%;
      z-index: 2; // Higher than typical z-index of 1 to account for elevated z-indez of input icons. Otherwise the dropdown appears behind the search icon.

      &-options {
        position: relative;

        &--item {
          cursor: pointer;
          display: flex;
          align-items: flex-start;
          padding: $token-spacing-3 $token-spacing-4;

          &:not(:last-of-type) {
            border-bottom: $token-border-width-default solid $token-border-base;
          }

          &_query {
            color: $token-text-color-primary;
            font-weight: $token-font-weight-med;
          }

          &:hover:not(.no-match),
          &[aria-selected="true"] {
            background-color: $token-background-secondary;
            color: $token-text-color-interaction;

            svg {
              fill: $token-text-color-interaction;
            }

            .usb-search_combobox-options--item_query {
              color: $token-text-color-interaction;
            }
          }

          &.no-match {
            cursor: text;
          }

          &_icon {
            margin-right: $token-spacing-2;
            height: $token-spacing-5;
            width: $token-spacing-5;
            overflow: hidden;

            & svg {
              max-width: $token-spacing-5;
              max-height: $token-spacing-5;
              min-width: $token-spacing-5;
              min-height: $token-spacing-5;
            }
          }

          & > * {
            pointer-events: none;
          }
        }
      }
    }
  }

  // Scope search select & modifiers

  // USBSelect !important style overrides

  .usb-search .usb-search_scope {
    & .usb--select--wrapper.usb-search_scope-select {
      margin-top: $token-spacing-0 !important;
      min-height: auto !important;
      height: $token-spacing-10 !important;
      max-height: $token-spacing-10 !important;

      &.is-focused {
        &::after {
          content: "";
          border: $token-border-width-large solid $search-input-focus-border-color !important;
          border-radius: $token-rounded-sm !important;
          height: calc(100% + #{$token-spacing-2}) !important;
          position: absolute !important;
          left: -$token-spacing-1 !important;
          top: -$token-spacing-1 !important;
          width: calc(100% + #{$token-spacing-2}) !important;
          z-index: -1 !important;
        }
      }

      & > .usb--error-message {
        display: none !important;
      }

      & > .helper_label--wrapper {
        display: none !important;
      }

      & > .usb--select--label {
        position: absolute !important;
        left: -100vw !important;
      }

      & .usb--select--container {
        border: $token-border-width-default solid $search-input-border-color !important;
        border-radius: $token-rounded-sm !important;
        box-shadow: none !important;
        height: $token-spacing-10 !important;
        margin-top: $token-spacing-0 !important;
        margin-left: $token-spacing-0 !important;
        padding: $token-spacing-2 !important;

        & .select--chevron {
          top: 0.625rem !important;
          right: $token-spacing-2 !important;
          pointer-events: none !important;
        }

      }
    }
  }

  // Scope select and search input connection
  .usb-search {
    &--row {
      & .usb-search_scope {
        flex-grow: $token-spacing-0;
        flex-shrink: $token-spacing-0;
      }
      .usb-search_scope .usb--select--wrapper.usb-search_scope-select .usb--select--container {
        border-top-right-radius: $token-spacing-0 !important;
        border-bottom-right-radius: $token-spacing-0 !important;
        border-right: $token-spacing-0 !important;

        & .usb--select {
          padding-right: $token-spacing-5 !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .usb-search_scope ~ .usb-search_container {
        & .usb-search_input {
          border-top-left-radius: $token-spacing-0;
          border-bottom-left-radius: $token-spacing-0;
        }
      }
    }

    &--column {
      & .usb-search-scope {
        width: 100%;
      }

      & .usb-search_scope .usb--select--wrapper.usb-search_scope-select .usb-search_scope-select {
        max-width: 100%;
      }

      & .usb-search_scope .usb--select--wrapper.usb-search_scope-select .usb--select--container {
        border-bottom-left-radius: $token-spacing-0 !important;
        border-bottom-right-radius: $token-spacing-0 !important;
        border-bottom: $token-spacing-0 !important;
      }

      .usb-search_scope ~ .usb-search_container {
        & .usb-search_input {
          border-top-left-radius: $token-spacing-0;
          border-top-right-radius: $token-spacing-0;
        }
      }

    }

    &_scope {
      transition: flex-basis 100ms ease;
      will-change: flex-basis;
    }
  }
}

@mixin devices-share-styles {
  .searchContainer {
    justify-content: center;
    margin: $token-spacing-base $token-spacing-0;

    .usbSearch {
      &_input {
        width: 100%;
        height: $token-line-height-40px;
        border-radius: $token-spacing-1;
      }
    }
  }
}

// Phones
@media #{$usb-max-sm} {
  @include devices-share-styles;
}

// Tablets
@media screen and (min-width: 480px) and (max-width: 1001px) {
  
  .searchContainer {
    justify-content: center;
    margin: $token-spacing-base $token-spacing-0;

    .usbSearch {
      &_input {
        width: 85%;  
        height: 40px;
        border-radius: 4px;
      }
    }
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1610px) {
  .searchContainer {
    .usbSearch {
      &_input {
        width: $inputSearch-searchContainer-usbSearch-input-width-mobile;
      }
    }
  }
}

@media only screen and (min-width: 1001px) {
  .searchContainer {
    margin: 1.25rem 0;
  }
}

@import '@/styles/theme/variables.scss';

.logoAndTaglineContainer{
  display: flex;

  img {
    width: $mobileNavBar-logo-width;
    height: auto;
  }
}

.profileLink {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
}

.twoTierLogoAndTaglineContainer {
  display: flex;
  margin-top: $token-spacing-2;
  margin-right: $token-spacing-3;
  img {
    width: $mobileNavBar-logo-width;
    height: auto;
  }
}

.subMenuWrapper {
  overflow-y: scroll;
  height: auto;
  min-height: 100% !important;
  background-color: $token-background-primary !important;
}

.subMenuWrapper .subMenu > li > a.nested {
  font-size: $token-font-size-sm;
}

.nested {
  padding: $token-spacing-5 $token-spacing-10 !important;
}

.level1 a.nested {
  font-weight: $token-font-weight-reg;
  padding: $mobileNavBar-level1-nested-padding !important;
}

.devAssistantLink {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
  margin-top: 1.5rem;
  margin-left: 1.25rem;
  font-size: 1rem !important;
  border-bottom: none !important;
}
.devAssistantName {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
  font-size: 0.75rem;
  border-bottom: none !important;

  span {
    position: relative;
    bottom: 0.25rem;
    left: 0.5rem;
    font-size: 16px !important;
    font-weight: $token-font-weight-reg !important;
  }
}

.level1 .inSessionMobileMenu a.nested::after, .level1 .inSessionMobileMenu a.devAssistantLink::after {
  display: none !important;
}

.level1 .inSessionMobileMenu .logoutBtn {
  margin-left:$token-spacing-0 !important;
  padding: $mobileNavBar-pageHeaderMobile-logoutBtn-padding !important;
  background: none !important;
  color: $token-text-color-primary !important;
  font-weight: $token-font-weight-reg !important;
  font-size: $token-font-size-base !important;
}

.pageHeader .headerBar, .pageHeader .twoTierHeaderBar, .headerBarWithContentCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $token-spacing-15;
  z-index: 10;
  position: relative;
}

.headerBarWithContentCenter {
  justify-content: center !important;
}

.pageHeader .headerBar .brand, .pageHeader .twoTierHeaderBar .brand {
  font-weight: $token-font-weight-bold;
}

.pageHeader .headerBar .social, .pageHeader .twoTierHeaderBar .search {
  display: flex;
}

.pageHeader .headerBar svg {
  fill: $mobileNavBar-icon-color;
}

.pageHeader .twoTierHeaderBar svg {
  fill: $token-neutral-90;
}

.pageHeader .menuWrapper {
  display: none;
  position: absolute;
  top: $token-spacing-15;
  left: $token-spacing-0;
  width: 80%;
  height: calc(100vh - 60px);
  overflow: hidden;
  z-index: 3;
}

.pageHeader .menuWrapper.is_visible {
  display: block !important;
}

.hamburgerIcon,
.hamburgerCloseIcon {
  padding: $mobileNavBar-hamburgerIcon-padding;
  border: 0;
  background: transparent;
}

#hamburgerCloseIcon,
.hamburgerCloseIcon {
  display: none;
  z-index: 999;
  border: 0;
  background: transparent;
}

.pageHeader .listWrapper {
  height: 100%;
  overflow-y: auto;
  background-color: $token-background-primary !important;

  label span {
    top: auto !important;
    margin-left: $mobileNavBar-pageHeader-label-span-margin-left !important;
  }

  input {
    margin-left: $mobileNavBar-pageHeader-input-margin-left !important;
    margin-right: $token-spacing-4 !important;
  }
}

.pageHeader .listWrapper:nth-child(2),
.pageHeader .listWrapper:nth-child(3),
.pageHeader .listWrapper:nth-child(4) {
  position: absolute;
  top: $token-spacing-0;
  left: $token-spacing-0;
  right: $token-spacing-0;
  transform: translateX(100%);
  backface-visibility: hidden;
  transition: transform 0.5s;
}

.pageHeader .listWrapper:nth-child(2).is_visible,
.pageHeader .listWrapper:nth-child(3).is_visible,
.pageHeader .listWrapper:nth-child(4).is_visible {
  transform: none;
}

.pageHeader .listWrapper:nth-child(1) > ul > li > .subMenu,
.pageHeader .listWrapper:nth-child(2) .level3,
.pageHeader ul.childPages {
  display: none;
}

.documentPages {
  display: block !important;
}

.pageHeader .menuWrapper a {
  display: block;
  position: relative;
  text-decoration: none;
  font-style: normal;
  line-height: $token-line-height-loose;
  /* identical to box height, or 150% */

  color: $token-text-color-primary;
  border-bottom: $token-border-width-default solid $token-border-base;
}

.backOneLevel.levelOne,
.backOneLevel.levelTwo,
.backOneLevel.levelThree {
  padding: $token-spacing-2 $token-spacing-4 !important;
}

.level1 li:first-child a {
  border-top: $token-border-width-default solid $token-border-base;
}

.level1 li:last-child {
  border-bottom: $mobileNavBar-level-1-li-last-child-border-bottom;
  border-top: $mobileNavBar-level-1-li-last-child-border-top;
}

.level1 li > a::after,
.subMenu li > a::after {
  content: url('/header/Chevron-Right.svg') !important;
  width: $token-spacing-4;
  height: $token-spacing-4;
  background-repeat: no-repeat;
  border: none;
  background-size: 100%;
  text-align: right;
  float: right;
  margin-right: $token-spacing-0;
}

.level3 li > a::after,
.level4 li > a::after,
.noArrow li > a::after {
  content: '';
  width: $token-spacing-4;
  height: $token-spacing-4;
  background-repeat: no-repeat;
  border: none;
  background-size: 100%;
  text-align: right;
  float: right;
  margin-right: $token-spacing-4;
}

.subMenu a:is(:only-child)::after {
  content: '' !important;
  width: $token-spacing-4;
  height: $token-spacing-4;
  background-repeat: no-repeat;
  border: none;
  background-size: 100%;
  text-align: right;
  float: right;
  margin-right: $token-spacing-4;
}

.nested span {
  width: 94%;
}

.activeLevelOneMenu,
.activeLevelTwoMenu,
.activeLevelThreeMenu {
  border-top: $token-border-width-default solid $token-border-base;
  border-bottom: $token-border-width-default solid $token-border-base !important;
  background-color: $token-background-secondary !important;
  font-weight: $token-font-weight-bold;
  font-size: $token-font-size-root;
  line-height: $token-line-height-loose;
  color: $token-color-base-darker-gray;
  padding: $token-spacing-5 $token-spacing-10;
}

ul.subMenu.level3.noArrow {
  border: none !important;
}

ul.subMenu li {
  font-size: $token-font-size-sm;
}

ul.documentPages li {
  font-size: $token-font-size-sm;
}

ul.subMenu.level3 ul.subMenu {
  border: none !important;
}

ul.subMenu.level3 li.greatGrandSubMenu {
  margin-left: $token-spacing-3 !important;
}

ul.subMenu.level3.noArrow li:last-child {
  border: none;
}

ul.subMenu.level3.noArrow > li {
  border-bottom: $token-border-width-default solid $token-border-base !important;
}

.pageHeader .backOneLevel {
  display: flex;
  align-items: center;
  background-color: $token-background-primary;
  border: 0;
}

.pageHeader .backOneLevel svg {
  content: url('/header/Chevron-Right.svg');
  margin: $token-spacing-0 $token-spacing-1;
  transform: rotate(360deg);
  width: $token-spacing-4;
  height: $token-spacing-4;
}

.backOneLevel span {
  font-size: $token-font-size-sm !important;
}

.listWrapper li {
  border-image: none;
  background-color: $token-background-primary !important;
}

.listWrapper a:active {
  color: $token-background-primary !important;
}

.listWrapper span {
  color: $token-text-color-primary !important;
}

.navArrow {
  width: $token-spacing-10;
  height: $token-spacing-10;
  left: calc(100% - 35px);
  top: calc(50% - 10px);
  padding: $token-spacing-1 $token-spacing-0 !important;
  position: absolute;
}

ul.subMenu img,
span.navArrow img {
  z-index: 1;
  width: $token-spacing-25px !important;
  height: $token-spacing-25px !important;
  position: absolute;
  float: right;
}

.logoTagline {
  padding: $token-spacing-3 !important;
  color: $token-background-primary !important;
  margin-bottom: $token-spacing-14px;
  font-weight: $token-font-weight-200;
  font-size: $token-font-size-lg;
}

.logoTagline img{
  margin-bottom: $token-spacing-0;

}
.logoTaglinePipe{
  font-size: $token-font-size-3xl !important;
  font-weight: $token-font-weight-100;
  padding-right: $token-spacing-2;
}

.greatGrandSubMenu li {
  margin: $token-spacing-0 !important;
}

.levelOne,
.levelTwo,
.levelThree {
  transition: transform 0.5s;
}

.showOverlay {
  display: none;
}

.pageHeader {
  width: 100%;
}

@mixin loginLink-common-style {
  color: $mobileNavBar-loginLink-color !important;
  text-decoration: none !important;
  margin-right: $token-spacing-9 !important;
  padding: $mobileNavBar-loginLink-padding;
  background: $mobileNavBar-loginLink-background;
  border-color: $mobileNavBar-loginLink-border-color;
  border-width: $mobileNavBar-loginLink-border-width;
  border-radius: $mobileNavBar-loginLink-border-radius;

  &:hover {
    background: $mobileNavBar-loginLink-hover-background;
  }

  &:active {
    background: $mobileNavBar-loginLink-active-background;
    color: $mobileNavBar-loginLink-active-color;
  }
}

@media only screen and (min-width: 667px) and (max-width: 1280px) {
  .loginLink {
    @include loginLink-common-style;
    margin-right: $token-spacing-9 !important;
  }
  .twoTierLoginLink {
    text-decoration: none !important;
    margin-right: $token-spacing-9 !important;
    margin-right: $token-spacing-9 !important;
    border: 1px solid $token-data-blue-3;
    color: $token-data-blue-3;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $token-spacing-8;
    font-size: $token-font-size-14px;
    max-height: $token-spacing-8;
    padding: $token-spacing-1 $token-spacing-3;
    border-radius: $token-spacing-2;
  }
  .twoTierLoginLink:hover {
    background: $token-data-blue-4;
    color: $token-neutral-0 !important;
  }
  .pageHeader .menuWrapper {
    width: 70% !important;
  }
  .level1 a.nested {
    font-weight: $token-font-weight-reg;
    padding: $token-spacing-5 $token-spacing-44px $token-spacing-5 $token-spacing-40px !important;
  }
  .devAssistantLink {
    margin-left: $token-spacing-9 !important;
  }
  .backOneLevel.levelOne,
  .backOneLevel.levelTwo,
  .backOneLevel.levelThree {
    padding: $token-spacing-2 $token-spacing-6 !important;
  }
  .activeLevelOneMenu,
  .activeLevelTwoMenu,
  .activeLevelThreeMenu {
    padding: $token-spacing-4 $token-spacing-2 $token-spacing-4 $token-spacing-10 !important;
  }
  .nested {
    padding: $token-spacing-4 $token-spacing-10 $token-spacing-4 $token-spacing-10 !important;
  }
  .level1 .inSessionMobileMenu .logoutBtn {
    margin-left:$token-spacing-0 !important;
    padding: $mobileNavBar-pageHeader-logoutBtn-padding !important;
    background: none !important;
    color: $token-text-color-primary !important;
    font-weight: $token-font-weight-reg !important;
    font-size: $token-font-size-base !important;
  }
}

@media only screen and (max-width: 667px) {
  .activeLevelOneMenu,
  .activeLevelTwoMenu,
  .activeLevelThreeMenu {
    padding: $token-spacing-4 $token-spacing-2 $token-spacing-4 $token-spacing-8 !important;
  }

  .nested {
    padding: $token-spacing-4 $token-spacing-2 $token-spacing-4 $token-spacing-8 !important;
  }

  .level1 li > a::after {
    margin-right: $token-spacing-2;
  }

  .subMenu li > a::after {
    margin-right: $token-spacing-4;
  }

  .hamburgerIcon svg,
  .hamburgerCloseIcon svg {
    width: $token-spacing-6 !important;
    height: $token-spacing-6 !important;
  }

  .logoTagline {
    font-size: $token-font-size-base !important;
    position: relative;
    padding: $token-spacing-0 !important;
    padding-left: $token-spacing-2 !important;
    margin-bottom: $token-spacing-14px;
  }

  .logoTagline img{
    margin-bottom: $token-spacing-0;
  }

  .loginLink {
    @include loginLink-common-style;
    margin-right: $token-spacing-4 !important;
  }

  .twoTierLoginLink {
    text-decoration: none !important;
    padding: $mobileNavBar-loginLink-padding;
    background: $mobileNavBar-loginLink-background;
    border-color: $mobileNavBar-loginLink-border-color;
    border-width: $mobileNavBar-loginLink-border-width;
    border-radius: $mobileNavBar-loginLink-border-radius;
    margin-right: $token-spacing-4 !important;
    margin-top: 0.25rem;
  }
}
@import '@/styles/theme/variables.scss';

.fullWidth {
  width: 100%;
  position: relative;
}

.twoTierShowOverlay {
  top: 7rem;
}

.twoTierWrapper {
  background-color: #FFFFFF !important;
}

.TwelveColumnSubGridFix {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.menuWrapper {
  width: 100%;
  display: flex;
  align-items: $desktopNavBar-menuWrapper-align-items;
}

.twoTierMenuWrapper {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.twoTierMenuWrapperElavon {
  @extend .twoTierMenuWrapper;

  padding-left: 0px;
  padding-right: 0px;
}

.twoTierMenuNavWrapper {
  height: 4rem;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  box-shadow: none;
  gap: .75rem;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;

    li {
      flex: auto;
      margin: 0.5rem;

      .searchContainer {
        margin-top: 1rem;
      }
      .bellIcon {
        svg {
          margin-top: 1.5rem;
          fill: #2D2D3E;
        }
      }
      .profileDropdown {
        z-index: 1000;
        right: 10rem;
      }
      .profileDropdownTierTop {
        right: $token-spacing-32px;
      }
      .loginModal {
        right: 2rem;
      }
    }
  }

}

.sectionRow {
  background-color: $token-background-brand-primary;
}

.logoTagline {
  font-weight: $token-font-weight-200 !important;
  font-size: $token-font-size-lg !important;
  line-height: $token-spacing-6 !important;
  padding: $token-spacing-17-5px $token-spacing-2 $token-spacing-2 !important;
  color: $token-background-primary !important;
  font-family: $token-font-base !important;
  -webkit-font-smoothing: antialiased;
  font-style: normal !important;
  float: right !important;
}

.logoTaglinePipe {
  font-size: $token-font-size-3xl !important;
  padding: $token-spacing-4;
  font-weight: $token-font-weight-100;
}

.sectionRow button {
  margin: $token-spacing-3 !important;
}

.navBarList {
  display: flex;
  height: $token-spacing-15;
}

.logoAndTaglineContainer {
  flex-grow: 1;

  img {
    width: $desktopNavBar-logo-width;
    height: auto;
    padding-top: $token-spacing-3 !important;
  }
}

.twoTierlogoAndTaglineContainer {
  float: left;

  img {
    width: $desktopNavBar-logo-width;
    height: auto;
    padding-top: $token-spacing-3 !important;
  }
}

.navMenuItems {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: $desktopNavBar-navMenuItems-justify-content;
  margin-left: 0;
}

.threeTiernavMenuItems {
  display: flex;
  flex: 1;
  justify-content: $desktopNavBar-navMenuItems-justify-content;
  padding: 0 1.25rem;
  width: fit-content;
  .mainMenu {
    padding: 0 !important;
  }
}

.parentMenuLink {
  color: #2E2E32;
  text-decoration: none;
  padding: $desktopNavBar-parentMenuLink-padding;
  &:hover {
    color: $token-data-blue-3 !important;
  }
}

.twoTierparentMenuLink {
  color: #2E2E32;
  text-decoration: none;
}

.parentMenuLinkNoSubMenu {
  color: $desktopNavBar-link-color;
  text-decoration: none;
}

.mainMenu {
  display: flex;
  flex-direction: row;
  color: $token-background-primary;
  padding: $token-spacing-0;
  margin: $desktopNavBar-mainMenu-spacing;

  li.parentMenu {
    display: none;
    margin: $token-spacing-2;

    /* Hide all */
    &:nth-child(-n + 4) {
      display: list-item;
    }
    /* Display first four */
  }

  li.twoTierparentMenu {
    display: none;

    /* Hide all */
    &:nth-child(-n + 4) {
      display: list-item;
    }
    /* Display first four */
  }
}

.grandSubMenuList {
  position: absolute !important;
  min-height: $token-spacing-12;
  padding: $token-spacing-3 $token-spacing-16 $token-spacing-3 $token-spacing-4 !important;
  font-weight: $token-font-weight-bold;
  font-size: $token-font-size-sm;
  line-height: $token-spacing-6;
  display: none;
  width: $token-spacing-261px;
  box-shadow: $token-shadow-dropdown-submenu;
}

.fourthLevelGrandSubMenuLinkWrapper {
  li {
    border-bottom: $desktopNavBar-dropDownSubMenu-border-bottom;
    padding-top: $token-spacing-3;
    padding-bottom: $token-spacing-3;

    &:hover {
      background-color: rgba(241, 245, 251, 1);
      color: $desktopNavBar-dropDownSubMenuForElavon-color-hover !important;
    }
  }  
}

.parentMenu {
  right: $token-spacing-0;
  top: $token-spacing-0;
  position: relative !important;
  z-index: 1000;
  font-weight: $token-font-weight-reg;
  font-size: $desktopNavBar-parentMenu-loginButton-font-size !important;
  line-height: $token-line-height-30px;
  color: $token-background-primary;
  padding: $desktopNavBar-parentMenu-spacing;

  a {
    margin-right: $desktopNavBar-parentMenu-a-margin-right;
  }
}

.twoTierparentMenu {
  right: $token-spacing-0;
  top: $token-spacing-0;
  position: relative !important;
  z-index: 1000;
  font-weight: $token-font-weight-reg;
  font-size: $desktopNavBar-parentMenu-loginButton-font-size !important;
  line-height: $token-line-height-30px;
  color: #2E2E32;
  margin: 0.5rem;

  a {
    margin-right: $desktopNavBar-parentMenu-a-margin-right;
  }
  ul.show {
    top: $twoTeirDesktopNavBar-menuSpacing;
  }
}

.parentMenuLink::after {
  content: url('/header/chevron-down.svg');
  border-width: $desktopNavBar-parentMenuLink-border-width;
  display: inline-block;
  position: relative;
  top: (-$token-spacing-small);
}

.twoTierparentMenuLink::after {
  content: '';
  border: solid #2E2E32;
  border-width: $desktopNavBar-parentMenuLink-border-width;
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: $token-spacing-5px;
  position: relative;
  top: (-$token-spacing-small);
}

li .subMenuLink :last-child {
  color: $token-color-base-red;
}

.show {
  left: $desktopNavBar-show-left;
  display: block;
  background: $token-background-primary;
  top: $desktopNavBar-show-top;
  position: absolute;
  width: $token-spacing-238-4px;
  padding: $token-spacing-0 !important;
  z-index: 1000;
  box-shadow: unquote($token-shadow-show);
}

.twoTeirShow {
  @extend .show;
  
  top: $twoTeirDesktopNavBar-show-top;
}

.dropDownMenu {
  padding: $token-spacing-0 !important;
  display: none;
  float: left;
  list-style: none;
}

.drop {
  height: auto;
  left: $token-spacing-237px;
  background-color: $token-background-primary;
  padding: $token-spacing-0 !important;
  position: absolute;
  top: -1px;
  box-shadow: $token-shadow-drop;
  border: $desktopNavBar-dropDownSubMenu-border-bottom;
  border-left-color: unset;
  border-radius: 4px;
  border-bottom: unset;
}

.dropDownSubMenu {
  position: initial !important;
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-sm;
  line-height: $token-spacing-6;
  border-top: none;
  border-right: none;
  border-bottom: $desktopNavBar-dropDownSubMenu-border-bottom;
  border-left: none;

  &:hover {
    background-color: $desktopNavBar-dropDownSubMenu-background-hover;
  }
}

.dropDownSubMenuForElavon {
  @extend .dropDownSubMenu;

  &:hover {
    color: $desktopNavBar-dropDownSubMenuForElavon-color-hover;
  }
}

.productsDropdown {
  width: auto !important;
  padding: $token-spacing-0 $token-spacing-6 $token-spacing-0 $token-spacing-0;

  button {
    &:focus,
    &:focus-visible {
      outline: $token-spacing-small solid $token-background-brand-interaction !important;
    }

    span {
      color: $token-color-grey-90 !important;
    }

    svg {
      fill: $token-color-grey-80 !important;
    }
  }

  ul {
    margin-top: $token-spacing-5 !important;

    width: max-content !important;
    max-width: max-content !important;
  }
}

.subMenuList {
  position: absolute !important;
  min-height: $token-spacing-12;
  font-weight: $desktopNavBar-dropDownSubMenu-font-weight;
  font-size: $desktopNavBar-dropDownSubMenu-font-size;
  line-height: $token-spacing-6;
  display: none;
  background-color: $dropdown-list_item-background-color;
  border: $desktopNavBar-dropDownSubMenu-border-bottom;
  box-shadow: $token-shadow-dropdown-menu;
  border-radius: 4px;
  border-bottom: unset;
}

.selected {
  text-decoration: none !important;
  background: $desktopNavBar-selected-background-color;
  border-left: 4px solid $token-data-blue-3;
  color: $token-data-blue-3 !important;
  min-width: $token-spacing-160px;

  .subMenuLink {
    color: $token-data-blue-3 !important;

    &::after {
      content: url('/header/Chevron-Right-selected.svg');
      position: absolute;
      background-repeat: no-repeat;
      height: $token-spacing-13-06px;
      width: $token-spacing-15-75px;
      right: $token-spacing-15px;
      margin-top: $token-spacing-3px;
      color: $token-data-blue-3;
    }
  }
}

.dropDownGrandSM {
  color: $token-background-brand-interaction;
  text-align: inherit;
  white-space: nowrap;
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-sm;
  line-height: $token-spacing-6;
  border: $token-border-none;
  padding: $token-spacing-10-4px $token-spacing-16 $token-spacing-3
    $token-spacing-4 !important;
  min-height: $token-spacing-12;
}

.greatGrandSmItem {
  min-height: $token-spacing-12;
  padding-top: $token-spacing-6;
  margin-left: $token-spacing-2;
}

.subMenuLink {
  font-style: normal;
  font-weight: $token-font-weight-reg;
  font-size: $desktopNavBar-dropDownSubMenu-font-size;
  line-height: $token-spacing-6;
  color: $token-neutral-90;
  text-decoration: none;
  padding: $token-spacing-3 !important;

  &:hover {
    background-color: rgba(241, 245, 251, 1);
  }  
}

.grandSubMenuLink {
  color: $desktopNavBar-grandSubMenuLink-color !important;
  text-decoration: none;
  padding: $token-spacing-3 $token-spacing-4 $token-spacing-3 $token-spacing-4;
  cursor: pointer;
  border-bottom: 1px solid $token-data-neutral-2;
  width: 100%;
  display: flex;

  &:hover {
    background-color: rgba(241, 245, 251, 1);
    color: $desktopNavBar-dropDownSubMenuForElavon-color-hover !important;
  }
  &::after {
    content: '';
    border-bottom: $desktopNavBar-dropDownSubMenu-border-bottom;
  }
}

.greatGrandSubMenuLink {
  color: $token-neutral-90 !important;
  text-decoration: none;
  padding: $token-spacing-3 $token-spacing-3 $token-spacing-3 $token-spacing-3;

  &:hover {
    background-color: rgba(241, 245, 251, 1);
    color: $desktopNavBar-dropDownSubMenuForElavon-color-hover !important;
  }
}

.grandSubMenuParentLink {
  color: $token-neutral-90;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $desktopNavBar-dropDownSubMenu-font-weight;
  font-size: $desktopNavBar-dropDownSubMenu-font-size;
  line-height: $token-line-height-loose;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  position: relative;
  padding: $desktopNavBar-subMenuLink-padding !important;

  &:hover {
    text-decoration: none;
    color: $desktopNavBar-dropDownSubMenuForElavon-color-hover;
  }
  &::after {
    content: url('/header/Chevron-Right-new.svg');
    position: absolute;
    background-repeat: no-repeat;
    height: $token-spacing-13-06px;
    width: $token-spacing-15-75px;
    right: $token-spacing-15px;
    margin-top: $token-spacing-3px;
    color: $token-data-blue-3;
  }
}

.isVisible {
  display: block;
}

.upArrow .parentMenuLink::after {
  content: url('/header/chevron-down.svg');
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  position: relative;
  top: $token-spacing-2px;
}

.upArrow .twoTierparentMenuLink::after {
  content: '';
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  position: relative;
  top: $token-spacing-5px;
}

.desktopMenuWrapper {
  width: 100%;
  display: block;
}

.profileIcon {
  svg {
    fill: #2D2D3E;
  }
  height: 25px
}

.profileIcon,
.profileIconStyled {
  margin-right: $token-spacing-2;
}

.profileIconStyled {
  display: inline-block;
  background-image: url('/header/profile_icon.svg');
  height: $token-spacing-29px;
  width: $token-spacing-29px;
  background-repeat: no-repeat;
}

.profileName,
.profileNameUpArrow {
  color: $desktopNavBar-profile-color;
  text-decoration: none;
  font-size: $token-font-size-sm;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    text-decoration: none !important;
  }
}

.profileName {
  &::after {
    border: $desktopNavBar-profile-icon-after-border;
    border-width: $token-border-width-0 $token-border-width-large
      $token-border-width-large $token-border-width-0;
    display: inline-block;
    padding: $token-spacing-4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-left: $token-spacing-2;
    position: relative;
    top: (-$token-spacing-small);
  }
}

.profileNameTierTop {
  @extend .profileName;

  &::after {
    padding: 0px;
  }
}

.profileNameUpArrow {
  &::after {
    border: $desktopNavBar-profile-icon-after-border-quickLinks;
    border-width: $token-border-width-0 $token-border-width-default
    $token-border-width-default $token-border-width-0;
    display: inline-block;
    padding: $token-spacing-3-22;
    margin-left: $token-spacing-2;
    position: relative;
  }
}

.profileNameUpArrow {
  &::after {
    content: '';
    top: .1rem;
    -webkit-transform: rotate(225deg);
  }
}

.profileNameDownArrow {
  @extend .profileNameUpArrow;
  
  &::after {
    content: '';
    top: -.20rem;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.profileDropdown {
  position: absolute;
  right: -($desktopNavBar-profileDropdown-right);
  top: $desktopNavBar-profileDropdown-top;
  background: $token-background-primary;
  padding: $desktopNavBar-profile-padding;
  width: $desktopNavBar-profile-width;
  display: none;
  border: $desktopNavBar-profileDropdown-border;
}

.profileDropdownTierTop {
  @extend .profileDropdown;
}

.closeProfileDropdown {
  position: absolute;
  right: $token-spacing-4;
  top: $token-spacing-4;
}

.showDropdown {
  display: block;
}

.profileUserInfo {
  float: right;
  color: $token-color-base-gray;
}

.profileUserName {
  font-size: $token-font-size-18px;
  line-height: $token-line-height-22px;
  margin-bottom: $token-spacing-1;
  font-weight: $token-font-weight-med;
}

.profileUserEmail {
  font-size: $token-font-size-root;
  line-height: $token-line-height-19px;
}

.profilemenu {
  clear: both;
  border-top: $token-border-width-default solid $token-border-base !important;
  padding-top: $token-spacing-4;
}

.profileDropdownLinks a {
  color: $token-text-color-interaction !important;
  text-decoration: none !important;
  line-height: $token-line-height-loose !important;
  display: block !important;
  margin-right: $token-spacing-3;
  font-weight: unset !important;
  margin-bottom: $token-spacing-1;

  &:hover {
    text-decoration: underline !important;
  }
}

.redLogout {
  color: $usbBadge-icon-red !important;
}

.profileAvatar {
  width: 33%;

  span {
    height: $token-spacing-24;
    width: $token-spacing-24;
    color: $token-text-color-inverse-primary;
    border-radius: 50%;
    font-size: $token-font-size-6xl;
    font-weight: $token-font-weight-med;
    line-height: $token-line-height-96px;
    text-align: center;
    vertical-align: top;
    background-color: $token-background-brand-primary;
    display: inline-block;
    padding: $token-spacing-0 !important;
  }
}

.profileUserSection {
  clear: both;
  margin: $token-spacing-0;
  padding-bottom: $token-spacing-3;
  display: flex;
  flex-wrap: wrap;
}

.logOffLink {
  background: $desktopNavBar-logOffLink-background-color !important;
  padding: $desktopNavBar-logOffLink-padding !important;
  color: $token-text-color-interaction !important;
  text-decoration: none;
  line-height: $token-line-height-loose;
  display: block;
  margin: $desktopNavBar-logOffLink-margin !important;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    > span {
      outline: -webkit-focus-ring-color auto $token-spacing-xs;
      padding: $token-spacing-1 $token-spacing-0;
    }

    &::before {
      border: none !important;
    }
  }
}

.loginButton {
  margin: $token-spacing-4 $token-spacing-0 !important;
  font-size: $desktopNavBar-parentMenu-loginButton-font-size !important;
  border-radius: $token-spacing-2 !important;
}

.loginModal {
  position: absolute;
  z-index: 3;
  background: $token-background-primary;
  margin-top: $token-spacing-4;
  width: $token-spacing-410px;
  padding: $token-spacing-12 $token-spacing-10 !important;
  box-shadow: unquote($token-shadow-modal);
  border: $desktopNavBar-loginModal-border;
  right: $token-spacing-0;
  min-height: auto;
  display: none;
}

.loginModalMFA {
  position: absolute;
  z-index: 3;
  background: $token-background-primary;
  margin-top: $token-spacing-4;
  width: $token-spacing-410px;
  padding: $token-spacing-12 $token-spacing-2 $token-spacing-4 $token-spacing-2 !important;
  box-shadow: unquote($token-shadow-modal);
  border: $desktopNavBar-loginModal-border;
  right: $token-spacing-0;
  min-height: $token-spacing-410px;
  max-height: auto;
  display: none;
}

.showLoginModal {
  display: block;
}

.closeLoginModal {
  position: absolute !important;
  right: $token-spacing-4;
  top: $token-spacing-0;
  opacity: 1;
  line-height: $token-line-height-tight;
  padding: $token-spacing-0 !important;
  margin: $token-spacing-0 !important;
  background: none !important;

  span {
    color: $token-color-base-darker-blue;
    text-shadow: initial;
    font-size: $token-font-size-3xl;
    font-weight: $token-font-weight-light;
    
    svg {
        fill: $token-color-base-darker-blue !important;
    }
  }

  &:hover {
    background: none !important;

    svg {
      fill: $token-color-base-darker-blue !important;
    }
  }
}

.profileLink {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
}

.devAssistantLink {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
  margin-top: 1rem;
}
.devAssistantName {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
  font-size: 0.75rem;

  span {
    position: relative;
    bottom: 0.375rem;
    font-size: $token-font-size-14px !important;
  }
}

.logoAndTaglineFullWidth {
  float: left;

  img {
    padding-top: $desktopNavBar-logo-padding-top !important;
    width: $desktopNavBar-logo-width;
    height: auto;
  }
}

.devAssistantLink {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
  margin: 1.25rem 1rem 0 0;
}
.devAssistantName {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
  font-size: 0.75rem;

  span {
    position: relative;
    bottom: 0.375rem;
    color: $token-color-grey-90 !important;
    &:hover {
      color: $token-data-blue-3 !important;
    }
  }
  img {
    width: $token-spacing-6;
    height: $token-spacing-6;
  }
}

.profileDetails,
.profileDetailsFullWidth {
  margin: $desktopNavBar-profileDetails-margin;
  padding: $desktopNavBar-profileDetails-twoTier-padding;
  z-index: 1000;
}

.profileDetailsTierTop {
  padding: $token-spacing-5 $token-spacing-0 $token-spacing-0 $token-spacing-0;
  z-index: 1000;
}

.profileDetailsFullWidth {
  .profileDropdown {
    right: $desktopNavBar-profileDetailsFullWidth-profileDropdown-right;
  }
}

@media #{$usb-max-1024} {
  .logoAndTaglineFullWidth {
    width: 33% !important;
  }

  .profileDetailsFullWidth {
    right: $token-spacing-0;
  }
}

@media only screen and (min-device-width: 1280px) and (max-device-width: $usb-screen-1368) {
  .logoAndTaglineFullWidth {
    img {
      padding-top: $token-spacing-4 !important;
      width: $token-spacing-230px;
      height: auto;
    }
  }

  .profileDetailsFullWidth {
    right: (-$token-spacing-5);
  }
}

@media only screen and (max-device-width: 1411px) {
  .profileDropdown {
    width: $token-spacing-350px !important;
  }

  .profileDetailsFullWidth .profileDropdown {
    width: $token-spacing-410px !important;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1179px) {
  .logoAndTaglineFullWidth {
    width: 30% !important;
  }
}

@media only screen and (min-device-width: 1180px) and (max-device-width: 1280px) {
  .logoAndTaglineFullWidth {
    width: 23%;

    img {
      padding-top: $token-spacing-4 !important;
      width: $token-spacing-210px;
      height: auto;
    }
  }
}

@media only screen and (min-width: $usb-screen-1024) and (max-width: 1498px) {
  .profileDetails {
    right: (-$token-spacing-16);
  }

  .profileDetailsFullWidth {
    .profileDropdown {
      right: $desktopNavBar-profileDropdown-right;
    }
  }

  .logoAndTaglineFullWidth {
    img {
      padding-top: $token-spacing-4 !important;
      width: $token-spacing-210px;
      height: auto;
    }
  }
}

@media (min-width: $token-breakpoint-x-large) {
  .fullWidth {
    max-width: none;
  }
  .twoTierWrapper {
    max-width: 100% !important;
  }
  .menuWrapper {
    border-bottom: 1px solid rgb(204, 204, 210);
  }
}

.whiteBGColor {
  padding-top: $token-spacing-3;
  padding-left: $token-spacing-3;
  padding-bottom: $token-spacing-3;
  width: 100%;
  background-color: $token-text-color-inverse-primary !important;

  a {
    padding: $token-spacing-0 $token-spacing-0 $token-spacing-0 $token-spacing-0 !important;
    &:hover {
      color: $desktopNavBar-dropDownSubMenuForElavon-color-hover !important;
    }
  }
  &:hover {
    background-color: rgba(241, 245, 251, 1) !important;
  }
  
}

@media only screen and (min-width: $usb-screen-820) and (max-width: 1338px) {
  .mainMenu {
    margin: $token-spacing-0 $token-spacing-0 $token-spacing-0 $token-spacing-0 !important;
  }
  .parentMenu {
    padding: $token-spacing-0 $token-spacing-1 $token-spacing-0 $token-spacing-0 !important;
  }
  .fullWidth .parentMenu {
    padding: $token-spacing-0 $token-spacing-0 $token-spacing-0 $token-spacing-0 !important;
  }
  .menuWrapper {
    padding: $token-spacing-0 $token-spacing-4;
  }
  .fullWidth .menuWrapper {
    padding: $token-spacing-0 $token-spacing-0;
  }
  .navMenuItems {
    justify-content: $desktopNavBar-navMenuItems-justify-content-mobile;
  }
}

@media only screen and (min-width: $usb-screen-820) and (max-width: 1424px) {
  .navMenuItems {
    justify-content: $desktopNavBar-navMenuItems-justify-content-mobile;
    margin-left: $token-spacing-0;
  }
  .fullWidth .parentMenu {
    padding: $desktopNavBar-parentMenu-spacing-mobile !important;
  }
  .logoAndTaglineFullWidth,
  .logoAndTaglineContainer {
    width: 20% !important;
    float: left;
    align-self: center;
    margin-bottom: $token-spacing-2;
  }
  .twoTierlogoAndTaglineContainer {
    float: left;
    align-self: center;
  }
}

@media only screen and (min-width: 1334px) and (max-width: 1473px) {
  .mainMenu {
    margin: $token-spacing-0;
  }
  .fullWidth .parentMenu {
    padding: $token-spacing-0 !important;
  }
}

@media only screen and (min-width: 1425px) and (max-width: 1500px) {
  .navMenuItems {
    margin-left: $token-spacing-2;
  }
}

@import '@/styles/theme/variables.scss';

.fullWidth {
  width: 100%;
}

a.usb-link.basic,
span.usb-link.basic {
  color: $link-basic-text-color !important;
}

.sectionRow {
  span.logoTagline {
    position: absolute !important;
  }

  ul {
    color: $token-text-color-inverse-primary !important;
  }
} 

.navBarList {
  display: flex;
}

.logoAndTaglineContainer {
  width: 30% !important;
  float: left;

  img {
    padding-top: $token-spacing-2 !important;
  }
} 

.navMenuItems {
  width: 60% !important;
}

.navButton {
  width: 10% !important;
}

@media only screen and (min-width: 280px) and (max-width: 667px) {

  .sectionRow button {
    margin: $token-spacing-2 !important;
    min-height: $token-spacing-4 !important;
    padding: $token-spacing-2 $token-spacing-4 !important;
  }
}

@media #{$usb-max-1024} {
  .sectionRow button {
    margin: $token-spacing-2 !important;
  }
}

.mobileNavBar,
.desktopNavBar {
  width: 100%;
  display: block;  
  position: relative;
  z-index: 10;
  height: $pageHeader-height;
  background-color: $pageHeader-desktop-backgroundcolor;
}

.twoTierMobileNavBar {
  width: 100%;
  display: block;  
  position: relative;
  z-index: 10;
  height: $pageHeader-height;
  background-color: $home-bgcolor-white;
}

.twoTierNavBar {
  width: 100%;
  display: block;  
  height: 7rem;
  position: relative;
  z-index: 10;
  background-color: $home-bgcolor-white;
}

.darkMode {
  position: fixed;
  width: 100%;
  top: $pageHeader-height;
  height: 100%;
  background-color: $token-background-dark-mode;
  z-index: 2;
  left: $token-spacing-0;
}

.twoTierDarkMode {
  @extend .darkMode;
  
  top: $twoTierPageHeader-topHeight;
}

@media only screen and (max-width: 1056px) {
  .desktopNavBar {
    display: none;
    box-shadow: $pageHeader-box-shadow;
  }

  .twoTierNavBar {
    display: none;
    box-shadow: $pageHeader-box-shadow;
  }
  .twoTierMenuSeperator {
    display: none;
  }


  .mobileNavBar {
    display: block;
    box-shadow: none;
  }
  .twoTierMobileNavBar {
    display: block;
    box-shadow: 0px 3px 4px 0px #0000001F;
  }
}

@media only screen and (min-width: 1057px) {
  .desktopNavBar, .twoTierNavBar {
    display: block;
    box-shadow: $pageHeader-box-shadow;
  }
  .twoTierMenuSeperator {
    display: block;
    width: 100%;
    border-top: 1px solid $token-neutral-30;
    position: absolute;
    bottom: 0;
    top: $token-spacing-48px;
    z-index: 9999;
  }

  .twoTierNavBar {
    height: $twoTierPageHeader-height;
    box-shadow: 0px 3px 4px 0px #0000001F;
  }

  .mobileNavBar {
    display: none;
    box-shadow: $pageHeader-box-shadow;
  }
  .twoTierMobileNavBar {
    display: none;
    box-shadow: $pageHeader-box-shadow;
  }
}

@media only screen and (min-device-width: $usb-screen-1024) and (max-device-width: $usb-screen-1368) {
  .desktopNavBar .sectionRow {
    padding: $token-spacing-0 $token-spacing-6 $token-spacing-0 $token-spacing-0;
  }
  .twoTierNavBar .sectionRow {
    padding: 0;
  }

}

@media (min-width: $token-breakpoint-x-large) { 
  .gridWrapper {
    max-width: 100%;
    margin: 0 !important;
  }
  .twoTierNavBar {
    .gridWrapper {
      max-width: 100% !important;
    }
  }
  
  .fullWidth {
    max-width: $pageHeader-fullWidth-width !important;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1600px) {
  .gridWrapperFullWidth {
    padding: $token-spacing-0 $token-spacing-4 !important;
  }
  .gridWrapper {
    padding: $token-spacing-0 $token-spacing-4 !important;
  }
  .twoTierNavBar {
    .gridWrapper {
      padding: 0 !important;
    }
    .gridWrapperFullWidth {
      padding: 0 !important;
    }
  }

}

.challengeWidgetdarkMode {
  position: fixed;
  width: 100%;
  top: $token-spacing-0;
  height: 100%;
  background-color: $token-background-dark-mode;
  z-index: 2;
  left: $token-spacing-0;
}